import { $api } from "@/api/$api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

export const useChangeNickname = () => {
  const client = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationKey: ["changeNickname"],
    mutationFn: async (nickname: string) => {
      const response = await $api.post(
        "/users/setNickname",
        {
          nickname,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      return response.data;
    },
    onSuccess: () => {
      client.invalidateQueries();

      toast.success("Success");
    },
    onError: () => {
      toast.error("Error");
    },
  });

  return { mutate, isPending };
};
