import { $api } from "@/api/$api";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export const useCheckToken = (token: string | null) => {
  const navigate = useNavigate();
  const { isLoading } = useQuery({
    queryKey: ["checkToken", token],
    queryFn: async () => {
      if (!token) return;
      const toastId = toast.loading("Loading...");
      try {
        await $api.get("/auth/checktoken", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        toast.success("Token is valid", {
          id: toastId,
        });
        localStorage.setItem("authToken", token);
        navigate("/account");
      } catch (error) {
        toast.error("Invalid token", {
          id: toastId,
        });
        navigate("/login");
      }
    },
  });

  return { isLoading };
};
