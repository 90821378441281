import {SVGProps} from 'react';

export function AccountDetailsIcon({...props}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2ZM9 7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7C15 8.65685 13.6569 10 12 10C10.3431 10 9 8.65685 9 7Z"
         
      />
      <path
        d="M12.0001 13C6.58967 13 3.5 17.5983 3.5 22H5.5C5.5 18.4017 7.97308 15 12.0001 15C16.0271 15 18.5 18.4017 18.5 22H20.5C20.5 17.5983 17.4106 13 12.0001 13Z"
         
      />
    </svg>
  );
}

export function Address({...props}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6797 21.9473L9 20.0541L3.31623 21.9487C3.01128 22.0503 2.67606 21.9992 2.41529 21.8112C2.15452 21.6233 2 21.3214 2 21V13H4L4 19.6126L8 18.2792L8 15H10V18.2792L14 19.6126V7.78078L12.7575 7.47014L13.2425 5.52986L14.96 5.95923L20.6838 4.05132C20.9887 3.94967 21.3239 4.00081 21.5847 4.18876C21.8455 4.37671 22 4.67856 22 5L22 19C22 19.4304 21.7246 19.8126 21.3162 19.9487L15.3162 21.9487C15.1256 22.0122 14.9232 22.0161 14.7345 21.9641C14.7161 21.9591 14.6979 21.9535 14.6797 21.9473ZM16 7.72076V19.6126L20 18.2792L20 6.38743L16 7.72076Z"
         
      />
      <path
        d="M6.5 7C7.05228 7 7.5 6.55229 7.5 6C7.5 5.44771 7.05228 5 6.5 5C5.94772 5 5.5 5.44771 5.5 6C5.5 6.55229 5.94772 7 6.5 7Z"
         
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.9 12.8C6.25556 13.0667 6.7449 13.0663 7.10045 12.7997L7.10236 12.7982L7.10619 12.7953L7.11812 12.7863L7.15852 12.7551C7.19255 12.7287 7.24049 12.6909 7.30037 12.6426C7.42004 12.5459 7.58789 12.4063 7.78791 12.2292C8.18656 11.8762 8.72004 11.368 9.25636 10.7479C10.2921 9.55034 11.5 7.75175 11.5 5.75C11.5 3.06683 9.19965 1 6.5 1C3.80035 1 1.5 3.06683 1.5 5.75C1.5 7.75175 2.70791 9.55034 3.74364 10.7479C4.27996 11.368 4.81344 11.8762 5.21209 12.2292C5.41211 12.4063 5.57996 12.5459 5.69963 12.6426C5.7595 12.6909 5.80745 12.7287 5.84148 12.7551L5.88188 12.7863L5.89381 12.7953L5.89764 12.7982L5.899 12.7993L5.9 12.8ZM6.5 3C8.21863 3 9.5 4.29103 9.5 5.75C9.5 6.99825 8.70791 8.32466 7.74364 9.4396C7.29644 9.95667 6.84661 10.3889 6.5 10.6981C6.15339 10.3889 5.70356 9.95667 5.25636 9.4396C4.29209 8.32466 3.5 6.99825 3.5 5.75C3.5 4.29103 4.78137 3 6.5 3Z"
         
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 6C7.5 6.55229 7.05228 7 6.5 7C5.94772 7 5.5 6.55229 5.5 6C5.5 5.44771 5.94772 5 6.5 5C7.05228 5 7.5 5.44771 7.5 6Z"
         
      />
    </svg>
  );
}

export function LogoutIcon({...props}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2H19V4H6.85078L9.62469 6.21913C9.86191 6.4089 10 6.69622 10 7V18H19V20H10V21C10 21.3844 9.77965 21.7348 9.43319 21.9013C9.08672 22.0678 8.67548 22.021 8.37531 21.7809L3.37531 17.7809C3.13809 17.5911 3 17.3038 3 17V3C3 2.44772 3.44772 2 4 2ZM8 7.48062L5 5.08062V16.5194L8 18.9194V7.48062Z"
         
      />
      <path
        d="M20.7071 11.7071C20.8946 11.5195 21 11.2652 21 11C21 10.7347 20.8946 10.4804 20.7071 10.2928L16.7071 6.29285L15.2929 7.70706L17.5858 9.99995L12 10V12L17.5858 12L15.2929 14.2928L16.7071 15.7071L20.7071 11.7071Z"
         
      />
    </svg>
  );
}

export function Balance({...props}: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" {...props}>
      <path d="M64 64C28.7 64 0 92.7 0 128V384c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H64zM272 192H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8 0-16-7.2-16-16s7.2-16 16-16zM256 304c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8 0-16-7.2-16-16zM164 152v13.9c7.5 1.2 14.6 2.9 21.1 4.7c10.7 2.8 17 13.8 14.2 24.5s-13.8 17-24.5 14.2c-11-2.9-21.6-5-31.2-5.2c-7.9-.1-16 1.8-21.5 5c-4.8 2.8-6.2 5.6-6.2 9.3c0 1.8 .1 3.5 5.3 6.7c6.3 3.8 15.5 6.7 28.3 10.5l.7 .2c11.2 3.4 25.6 7.7 37.1 15c12.9 8.1 24.3 21.3 24.6 41.6c.3 20.9-10.5 36.1-24.8 45c-7.2 4.5-15.2 7.3-23.2 9V360c0 11-9 20-20 20s-20-9-20-20V345.4c-10.3-2.2-20-5.5-28.2-8.4l0 0 0 0c-2.1-.7-4.1-1.4-6.1-2.1c-10.5-3.5-16.1-14.8-12.6-25.3s14.8-16.1 25.3-12.6c2.5 .8 4.9 1.7 7.2 2.4c13.6 4.6 24 8.1 35.1 8.5c8.6 .3 16.5-1.6 21.4-4.7c4.1-2.5 6-5.5 5.9-10.5c0-2.9-.8-5-5.9-8.2c-6.3-4-15.4-6.9-28-10.7l-1.7-.5c-10.9-3.3-24.6-7.4-35.6-14c-12.7-7.7-24.6-20.5-24.7-40.7c-.1-21.1 11.8-35.7 25.8-43.9c6.9-4.1 14.5-6.8 22.2-8.5V152c0-11 9-20 20-20s20 9 20 20z" />
    </svg>
  );
}

export function Password({...props}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path d="M13 15V18H11V15H13Z"   />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C10.6739 2 9.40215 2.52678 8.46447 3.46447C7.52678 4.40215 7 5.67392 7 7L7 11H5C4.44772 11 4 11.4477 4 12V21C4 21.5523 4.44772 22 5 22H19C19.5523 22 20 21.5523 20 21V12C20 11.4477 19.5523 11 19 11H17V7C17 5.67392 16.4732 4.40215 15.5355 3.46447C14.5979 2.52678 13.3261 2 12 2ZM9 7V11H15V7C15 6.20435 14.6839 5.44129 14.1213 4.87868C13.5587 4.31607 12.7956 4 12 4C11.2044 4 10.4413 4.31607 9.87868 4.87868C9.31607 5.44129 9 6.20435 9 7ZM6 13V20H18V13H6Z"
         
      />
    </svg>
  );
}

export function Subscription({...props}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4693 1.30448C12.9546 1.10346 13.4747 1 14 1C14.5253 1 15.0454 1.10346 15.5307 1.30448C16.016 1.5055 16.457 1.80014 16.8284 2.17157C17.1999 2.54301 17.4945 2.98396 17.6955 3.46927C17.8965 3.95457 18 4.47471 18 5V6H20C21.1046 6 22 6.89543 22 8V19C22 20.1046 21.1046 21 20 21H16V19H20V8H18V10H16V8H12V10H10V8H8V11H6V8C6 6.89543 6.89543 6 8 6H10V5C10 4.47471 10.1035 3.95457 10.3045 3.46927C10.5055 2.98396 10.8001 2.54301 11.1716 2.17157C11.543 1.80014 11.984 1.5055 12.4693 1.30448ZM16 5V6H12V5C12 4.73736 12.0517 4.47728 12.1522 4.23463C12.2528 3.99198 12.4001 3.7715 12.5858 3.58579C12.7715 3.40007 12.992 3.25275 13.2346 3.15224C13.4773 3.05173 13.7374 3 14 3C14.2626 3 14.5227 3.05173 14.7654 3.15224C15.008 3.25275 15.2285 3.40007 15.4142 3.58579C15.5999 3.7715 15.7472 3.99198 15.8478 4.23463C15.9483 4.47728 16 4.73736 16 5Z"
         
      />
      <path
        d="M12 18.5C10.8954 18.5 10 19.3954 10 20.5C10 21.6046 10.8954 22.5 12 22.5C13.1046 22.5 14 21.6046 14 20.5C14 19.3954 13.1046 18.5 12 18.5Z"
         
      />
      <path
        d="M2 14.5C2 13.3954 2.89543 12.5 4 12.5C5.10457 12.5 6 13.3954 6 14.5C6 15.6046 5.10457 16.5 4 16.5C2.89543 16.5 2 15.6046 2 14.5Z"
         
      />
      <path
        d="M4.20718 22.7071L13.2072 13.7071L11.793 12.2929L2.79297 21.2929L4.20718 22.7071Z"
         
      />
    </svg>
  );
}
