import { useMyTransaction } from "@/hooks/use-my-transaction.hooks";

const AccountTransaction = () => {
  const { isLoading, transactions, refetch, isPending } = useMyTransaction();
  return isLoading ? (
    <p>loading</p>
  ) : (
    <div className="account__content">
      <div className="flex justify-between">
        <h1 className="account__content__title">Transaction history</h1>
        <div className="">
          <button
            onClick={() => refetch()}
            disabled={isPending}
            className="border border-white rounded text-white px-4 py-2 hover:bg-white hover:text-black transition-all duration-200 ease-in-out"
          >
            Refetch
          </button>
        </div>
      </div>
      {transactions && transactions.length !== 0 ? (
        <>
          <div className="account__transaction__wrapper">
            {transactions.map((transaction) => (
              <div className="account__transaction__block">
                <div className="account__transaction__header">
                  <span className="number">{transaction.orderId}</span>
                  <span className="status">{transaction.status}</span>
                </div>
                <div className="account__transaction__body">
                  <div className="account__transaction__body--row">
                    <div className="date">
                      <span>{transaction.createdAt}</span>
                    </div>
                  </div>
                  <div className="account__transaction__body--row">
                    <h2 className="amount">
                      {transaction.paymentAmount} <span>BTC</span>
                    </h2>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <button className="loginBtn downloadBtn" type="submit">
            Load more
          </button>
        </>
      ) : (
        <p className="text-white text-center">No transactions</p>
      )}
    </div>
  );
};

export default AccountTransaction;
