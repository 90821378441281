// Modules Types
import type { FC } from "react";

// Screens
import { AccountMenu } from "@/screens/account-screen/account-menu";
import { Outlet } from "react-router-dom";
import MainWrapper from "@/components/MainWrapper";
import { useProfile } from "@/hooks/use-profile.hooks";

export const AccountScreenContainer: FC = (): JSX.Element => {
  const { isLoading } = useProfile();
  return isLoading ? (
    <p>loading</p>
  ) : (
    <MainWrapper>
      <main className="account container mx-auto">
        <AccountMenu />
        <Outlet />
      </main>
    </MainWrapper>
  );
};

export default AccountScreenContainer;
