// Modules
import { lazy } from 'react';

// Interfaces
import { IRoute } from '@/interfaces/IRoute';

const accountScreenContainer = lazy(async () => await import('../screens/account-screen/account-screen-container'));
const landingScreenContainer = lazy(
  async () => await import("../screens/landing-screen/landing-screen-container")
);
const loginScreenContainer = lazy(
  async () => await import("../screens/login-screen/login-screen-container")
);
const RecoverScreenContainer = lazy(
  async () => await import("../screens/login-screen/recover-screen-container")
);


export const screenRoutes: IRoute[] = [
  {
    path: "account/*",
    component: accountScreenContainer,
  },
  {
    path: "/",
    component: landingScreenContainer,
  },
  {
    path: "/login",
    component: loginScreenContainer,
  },
  {
    path: "/recover",
    component: RecoverScreenContainer,
  },
];

// TODO Add 2 more variables of routes as mainRoutes and dashboardRoutes and the last one rename into authRoutes
