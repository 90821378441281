import { $api } from "@/api/$api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

interface IUseChangePassword {
  setForm: React.Dispatch<
    React.SetStateAction<{
      password: string;
      confirmPassword: string;
      error: string;
    }>
  >;
}

export const useChangePassword = ({ setForm }: IUseChangePassword) => {
  const client = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationKey: ["changePassword"],
    mutationFn: async (password: string) => {
      const response = await $api.post(
        "/users/setPassword",
        {
          password,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      return response.data;
    },
    onSuccess: () => {
      client.invalidateQueries();
      toast.success("Success");
      setForm({
        password: "",
        confirmPassword: "",
        error: "",
      });
    },
    onError: () => {
      toast.error("Error");
    },
  });

  return { mutate, isPending };
};
