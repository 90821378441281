// Modules
import { Route } from "react-router-dom";

// Containers
import { MainContainer } from "@/containers/main-container/main-container";

// Guards
import { UnAuthGuard } from "@/guards/un-auth-guard";

// Screens
// import { LoginScreenContainer } from "../screens/login-screen/login-screen-container";
import { AccountScreenContainer } from "@/screens/account-screen";
import AccountDetails from "@/components/account/AccountDetails";
import AccountPassword from "@/components/account/AccountPassword";
import AccountBalance from "@/components/account/AccountBalance";
import AccountTransaction from "@/components/account/AccountTransaction";

export const UnAuthRoutes = [
  // <Route key="auth-container">
  //     <Route key="LoginScreenContainer" path="/" element={<UnAuthGuard component={<LoginScreenContainer />} />} />
  //     {/*<Route key="Login2ScreenContainer" path="/login" element={<UnAuthGuard component={<LoginScreenContainer />} />} />*/}
  // </Route>
  <Route
    key="MainContainer"
    path="/*"
    element={<UnAuthGuard component={<MainContainer />} />}
  >
    <Route index />
  </Route>,
  <Route
    key="MainContainer"
    path="/account"
    element={<UnAuthGuard component={<AccountScreenContainer />} />}
  >
    <Route index element={<AccountDetails />} />
    <Route path="details" element={<AccountDetails />} />
    <Route path="password" element={<AccountPassword />} />
    <Route path="balance" element={<AccountBalance />} />
    <Route path="transaction" element={<AccountTransaction />} />
  </Route>,
];
