// Modules
import { Suspense } from "react";
import { Routes } from "react-router-dom";

// Modules Types
import { FC } from "react";
import "./assets/style/main.scss";

// Toast
import { Toaster } from "react-hot-toast";

import { AuthRoutes, UnAuthRoutes } from "@/routes";

// React Query
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

// React Query client init
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const App: FC = (): JSX.Element => {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={"...loading"}>
          <Routes>
            {AuthRoutes}
            {UnAuthRoutes}
          </Routes>
        </Suspense>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
      <Toaster />
    </div>
  );
};
