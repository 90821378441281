import { useMyWallet } from "@/hooks/use-my-wallet.hooks";
import Input from "../UI/Input";
import { Balance } from "@/assets/img/icons/AccountMenuIcons";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { useCreatePayment } from "@/hooks/use-create-payment.hook";
const AccountBalance = () => {
  const { isLoading, wallet } = useMyWallet();
  const { mutate, isPending } = useCreatePayment();
  const [formDeposit, setFormDeposit] = useState({
    amount: 0,
    error: "",
  });
  const handleDepositForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!wallet?.userId) {
      return;
    }
    if (formDeposit.amount <= 0) {
      setFormDeposit({
        ...formDeposit,
        error: "Amount must be greater than 0",
      });
      return;
    }
    mutate({
      userId: wallet.userId,
      amount: formDeposit.amount,
      orderId: uuidv4(),
    });
  };
  return isLoading ? (
    <p>Loading</p>
  ) : (
    <div className="account__content">
      <h1 className="account__content__title">Account balance</h1>
      <p className="account__content__balance--title">Estimated Balance</p>
      <h2 className="account__content__balance">
        {wallet?.cryptoBalance} <span>BTC</span>
      </h2>
      <div className="account__content__money">
        <form
          className="account__content__money--btn"
          onSubmit={handleDepositForm}
        >
          <Input
            label="Deposit"
            type="text"
            name="deposit"
            Icon={Balance}
            value={formDeposit.amount}
            errorMassage={formDeposit.error}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFormDeposit({ ...formDeposit, amount: +e.target.value })
            }
          />
          <button
            className="loginBtn downloadBtn deposit"
            type="submit"
            disabled={isPending}
          >
            Deposit
          </button>
        </form>
        <div className="account__content__money--btn">
          <Input label="Withdraw" type="text" name="withdraw" Icon={Balance} />
          <button className="loginBtn downloadBtn withdraw" type="submit">
            Withdraw
          </button>
        </div>
        <div className="account__content__money--inputs"></div>
      </div>
    </div>
  );
};

export default AccountBalance;
