import React, { FC } from "react";
import { Link } from "react-router-dom";

const Header: FC = () => {
  const user = localStorage.getItem("authToken");
  return (
    <header>
      <nav className="flex container mx-auto justify-between items-center pr-3 pl-3">
        <div className="logo">
          <Link to={"/"}>
            <img src={require("@/assets/img/logo.png")} alt="logo" />
          </Link>
        </div>
        <span className="tagline">
          Bet on Your Card: <br /> Your Game, Your Win!
        </span>

        {!user ? (
          <Link to={"/login"}>
            <div className="navLink">Login</div>
          </Link>
        ) : (
          <Link to={"/account"}>
            <div className="navLink">Account</div>
          </Link>
        )}
      </nav>
    </header>
  );
};

export default Header;
