// Modules Types
import type { FC } from "react";

// Interfaces
import type { IProps } from "./IProps";

export const AuthGuard: FC<IProps> = (props) => {
  const { component } = props;

  return <>{component}</>;
};
