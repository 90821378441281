import Input from "../UI/Input";
import PasswordIcon from "@/assets/img/icons/PasswordIcon";
import { useChangePassword } from "@/hooks/use-change-password.hook";
import { useState } from "react";

const AccountPassword = () => {
  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
    error: "",
  });
  const { mutate, isPending } = useChangePassword({ setForm });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (form.password !== form.confirmPassword) {
      setForm({ ...form, error: "Passwords do not match" });
      console.log(form);
      return;
    }
    if (form.password.length < 1) {
      setForm({ ...form, error: "Password is required" });
      return;
    }
    mutate(form.password);
  };
  return (
    <form className="account__content" onSubmit={handleSubmit}>
      <h1 className="account__content__title">Change Password</h1>
      <div className="account__content__inputs">
        <Input
          label="New Password"
          type="password"
          name="password"
          Icon={PasswordIcon}
          value={form.password}
          errorMassage={form.error}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setForm({ ...form, password: e.target.value })
          }
        />
        <Input
          label="Repeat New Password"
          type="password"
          name="confirmPassword"
          Icon={PasswordIcon}
          value={form.confirmPassword}
          errorMassage={form.error}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setForm({ ...form, confirmPassword: e.target.value })
          }
        />
      </div>
      <button
        className="loginBtn downloadBtn"
        type="submit"
        disabled={isPending}
      >
        Change
      </button>
    </form>
  );
};

export default AccountPassword;
