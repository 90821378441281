// Modules Types
import {
  Password,
  Address,
  LogoutIcon,
  Balance,
  AccountDetailsIcon,
} from "@/assets/img/icons/AccountMenuIcons";
import type { FC } from "react";
import { NavLink } from "react-router-dom";

export const AccountMenu: FC = (): JSX.Element => {
  return (
    <div className="account__menu">
      {/* <div className="account__menu__title">My Account</div> */}
      <nav className="account__menu__nav" role="navigation">
        <div className="account__menu__row">
          <NavLink className="account__menu__link" to="/account/details">
            <AccountDetailsIcon />
            Account Details
          </NavLink>
          <NavLink className="account__menu__link" to="/account/password">
            <Password />
            Change Password
          </NavLink>
          <NavLink to="/account/balance" className="account__menu__link">
            <Balance />
            Balance
          </NavLink>
        </div>
        <div className="account__menu__row">
          <NavLink to="/account/transaction" className="account__menu__link">
            <Address />
            Transaction history
          </NavLink>
          <NavLink
            to="/"
            className="account__menu__link"
            onClick={() => {
              localStorage.removeItem("authToken");
            }}
          >
            <LogoutIcon />
            Logout
          </NavLink>
        </div>
      </nav>
    </div>
  );
};
