import { $api } from "@/api/$api";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

type IUserEntity = {
  id: number;
  createdAt: string;
  updatedAt: string;
  nickname: string;
  email: string;
  avatar: number;
};

export const useProfile = () => {
  const navigate = useNavigate();
  const { isLoading, data: user } = useQuery({
    queryKey: ["getProfile"],
    queryFn: async () => {
      try {
        const token = localStorage.getItem("authToken");
        const { data } = await $api.get<IUserEntity>("/users/get-me", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (error) {
        localStorage.removeItem("authToken");
        navigate("/login");
      }
    },
  });

  return { isLoading, user };
};
