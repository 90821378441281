import { $api } from "@/api/$api";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

type IPayment = {
  id: number;
  userId: number;
  uuid: string;
  orderId: string;
  status: string;
  amount: string;
  paymentAmount: string | null;
  isFinal: boolean;
  url: string;
  createdAt: string;
  updatedAt: string;
};

export const useMyTransaction = () => {
  const navigate = useNavigate();
  const {
    isLoading,
    data: transactions,
    refetch,
    isPending,
  } = useQuery({
    queryKey: ["getMyTransaction"],
    queryFn: async () => {
      try {
        const token = localStorage.getItem("authToken");
        const { data } = await $api.get<IPayment[]>(
          "/crypto/get-user-payments",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return data;
      } catch (error) {
        localStorage.removeItem("authToken");
        navigate("/login");
      }
    },
  });

  return { isLoading, transactions, refetch, isPending };
};
