import Input from "../UI/Input";
// import EmailIcon from "@/assets/img/icons/EmailIcon";
import { AccountDetailsIcon } from "@/assets/img/icons/AccountMenuIcons";
import { useProfile } from "@/hooks/use-profile.hooks";
import { useChangeNickname } from "@/hooks/use-change-nickname.hook";
import { useState } from "react";

const AccountDetails = () => {
  const { isLoading, user } = useProfile();
  const [nickname, setNickname] = useState(user?.nickname || "");
  const { mutate, isPending } = useChangeNickname();

  const handleForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(nickname);
  };
  return isLoading ? (
    <p>loading</p>
  ) : (
    <form className="account__content" onSubmit={handleForm}>
      <h1 className="account__content__title">Account details</h1>
      <div className="account__content__inputs">
        <Input
          label="Name"
          type="text"
          name="name"
          Icon={AccountDetailsIcon}
          value={nickname}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setNickname(e.target.value)
          }
        />
        {/* <Input
          label="Email"
          type="email"
          name="email"
          Icon={EmailIcon}
          value={user?.email}
        /> */}
      </div>
      <button
        className="loginBtn downloadBtn"
        type="submit"
        disabled={isPending}
      >
        Save
      </button>
    </form>
  );
};

export default AccountDetails;
