import { $api } from "@/api/$api";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

type IWalletEntity = {
  id: number;
  createdAt: string;
  updatedAt: string;
  userId: number;
  cryptoBalance: number;
  gameBalance: number;
};

export const useMyWallet = () => {
  const navigate = useNavigate();
  const { isLoading, data: wallet } = useQuery({
    queryKey: ["getWallet"],
    queryFn: async () => {
      try {
        const token = localStorage.getItem("authToken");
        const { data } = await $api.get<IWalletEntity>("/wallet/get-wallet", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (error) {
        localStorage.removeItem("authToken");
        navigate("/login");
      }
    },
  });

  return { isLoading, wallet };
};
