import React, { FC } from 'react';
import Header from './header/Header';
import Footer from './footer/Footer';

interface MainWrapperProps{
    children: React.ReactNode
}

const MainWrapper: FC<MainWrapperProps> = ({children}) => {
    return (
        <>
            <Header/>
            {children}
            <Footer/>
        </>
    );
};

export default MainWrapper;