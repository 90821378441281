import { $api } from "@/api/$api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

interface IUseCreatePayment {
  userId: number;
  amount: number;
  orderId: string;
}

interface IPaymentResult {
  state: number;
  result: {
    uuid: string;
    order_id: string;
    amount: string;
    payment_amount: string;
    payer_amount: string;
    discount: string;
    currency: string;
    merchant_amount: string;
    network: string;
    address: string;
    from: string;
    txid: string;
    payment_status: string;
    url: string;
    expired_at: number;
    status: string;
    is_final: boolean;
    additional_data: string;
    created_at: string;
    updated_at: string;
  };
}

export const useCreatePayment = () => {
  const navigate = useNavigate();
  const client = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationKey: ["createPayment"],
    mutationFn: async (body: IUseCreatePayment) => {
      const response = await $api.post<IPaymentResult>(
        "/crypto/createPayment",
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      return response.data;
    },
    onSuccess: ({ result }) => {
      client.invalidateQueries();
      window.open(result.url, "_blank");
      navigate("/account/transaction");
      toast.success("Success");
    },
    onError: () => {
      toast.error("Error");
    },
  });

  return { mutate, isPending };
};
