import React, { ComponentType, FC, HTMLInputTypeAttribute } from "react";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  Icon: ComponentType;
  label: string;
  type: HTMLInputTypeAttribute;
  name: string;
  errorMassage?: string;
}

const Input: FC<InputProps> = ({
  label,
  Icon,
  type,
  name,
  errorMassage,
  ...props
}) => {
  return (
    <div className="myInput">
      <div className="flex justify-between">
        <span className="myInput__label">{label}</span>
        {errorMassage && <span className=" text-red-600">{errorMassage}</span>}
      </div>
      <div className="myInput__wrapper">
        <span className="myInput__icon">{Icon && <Icon />}</span>
        <input id={name} name={name} type={type} {...props} />
      </div>
    </div>
  );
};

export default Input;
