// Modules
import { Route } from 'react-router-dom';

// Guards
import { AuthGuard } from '@/guards/auth-guard';

// Screens
import { AccountScreenContainer } from '@/screens/account-screen/account-screen-container';
import { MainContainer } from '@/containers/main-container/main-container';

export const AuthRoutes = [
  <Route key="MainContainer" path="/*" element={<AuthGuard component={<MainContainer />} />}>
    <Route key="AccountScreenContainer" element={<AuthGuard component={<AccountScreenContainer />} />} />
  </Route>,
  
];
