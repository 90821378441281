import React from 'react';

const Footer = () => {
  return (
    <footer className="p-4 text-center">
      <p>© 2023 Company Name</p>
    </footer>
  );
};

export default Footer;