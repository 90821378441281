// Modules
import { Route, Routes, useLocation } from "react-router-dom";

// Modules Types
import { type FC } from "react";

// Constants
import { screenRoutes } from "@/constants/routes";
import { useCheckToken } from "@/hooks/use-check-token.hook";

export const MainContainer: FC = (): JSX.Element => {
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");
  useCheckToken(token);
  return (
    <>
      <Routes>
        {screenRoutes.map(({ component: Component, path }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Routes>
    </>
  );
};
